import React, { useEffect, useState } from 'react'
import tw, { theme, css, styled } from 'twin.macro'
import { FormattedMessage } from 'react-intl'
//
import Icons from '~storybook/icons/index'
import { useComponentVisible } from '~utils/dropdown'
import { MenuContents, MenuContentsMobile } from './menu'
import { SearchContents } from './search'
import NavLink from '~utils/navlink'

const NavigationWrapper = styled(({ hasExtraMenu, hasTallerLogo, hasLogo, hasCenteredLogo, ...other }) => (
  <div {...other} />
))`
  ${tw`block z-10`}
  ${({ hasCenteredLogo }) => !hasCenteredLogo && tw`lg:pt-7`}
  ${({ hasCenteredLogo, hasExtraMenu }) => !hasCenteredLogo && hasExtraMenu && tw`lg:pt-8`}
  ${({ hasCenteredLogo, hasLogo }) => !hasCenteredLogo && hasLogo && tw`lg:pt-8`}
  ${({ hasCenteredLogo, hasExtraMenu, hasLogo, hasTallerLogo }) =>
    !hasCenteredLogo && hasExtraMenu && hasLogo && hasTallerLogo && tw`lg:pt-7`}
  ${({ hasCenteredLogo }) => hasCenteredLogo && tw`pt-6 pb-6 md:pt-10 md:pb-10`}
`

const NavigationBlock = styled(({ hasTallerLogo, hasLogo, hasCenteredLogo, ...other }) => <div {...other} />)`
  ${tw`flex flex-row items-end lg:items-center justify-between pt-6 md:pt-12 lg:pt-8`}
  ${({ hasLogo }) => hasLogo && tw`justify-between pt-6 md:pt-8 lg:pt-0`}
  ${({ hasLogo, hasTallerLogo }) => hasLogo && hasTallerLogo && tw`pt-4 md:pt-6 lg:pt-0`}
  ${({ hasCenteredLogo }) => hasCenteredLogo && tw`justify-center`}
`

const NavigationLeftContainer = styled(({ hasBurgerMenu, ...other }) => <div {...other} />)`
  ${tw`flex flex-row flex-grow`}
  ${({ hasBurgerMenu }) => !hasBurgerMenu && tw`items-center`}
`

const ExtraNavItem = styled(({ active, ...other }) => <NavLink {...other} />)`
  ${tw`ml-5 no-underline font-thin text-c-header-extraNavItem hover:text-c-header-extraNavItemHover hover:underline`}
`

const NavItem = styled(({ hasItems, active, ...other }) => <NavLink {...other} to={other?.to || '#'} />)`
  ${tw`inline-flex text-c-header-navItem no-underline font-thin text-lg mr-6 mt-2 pb-5 md:pb-8 lg:pb-10 lg:border-b-4 border-transparent`}
  ${tw`hover:(lg:border-b-4 border-solid border-c-header-borderActive)`}
  ${({ active }) => active && tw`lg:border-b-4 border-solid border-c-header-borderActive`}
`

const MenuContainer = styled.div`
  ${tw`block left-0 w-full lg:border-t border-solid border-c-header-borderMenuContainer`}
  width: 100vw;
  height: 100px;
`
const SelectChevron = styled.span`
  ${css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' fill='%23323341' aria-hidden='true'%3E%3Cpolygon fill='currentColor' points='0,12.438 48,12.438 24,35.562 ' /%3E%3C/svg%3E");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 5px;
    top: 6px;
    height: 18px;
  `}
  ${tw`relative flex ml-2 items-center pointer-events-none text-black`}
`
const NavButton = styled(({ active, ...other }) => <button type="button" {...other} />)`
  ${tw`leading-none block focus:outline-none text-lg ml-2 md:ml-8 pb-5 md:pb-9 lg:pb-8 lg:border-b-4 border-transparent`}
  ${tw`hover:(lg:border-b-4 border-solid border-c-header-borderActive)`}
  ${({ active }) => active && tw`lg:border-b-4 border-solid border-c-header-borderActive`}

  margin-top: 2px;

  svg {
    width: 2rem;
    height: 2rem;
    margin-top: -2px;
  }
`

const Logo = styled.div`
  img {
    display: block;
    margin-left: ${({ hasCenteredLogo }) => (hasCenteredLogo ? 'auto' : '0')};
    margin-right: ${({ hasCenteredLogo }) => (hasCenteredLogo ? 'auto' : '0')};

    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      width: ${({ widthSm }) => (widthSm ? `${widthSm}px` : 'auto')};
      height: ${({ heightSm }) => (heightSm ? `${heightSm}px` : 'auto')};
    }
  }
`

const SiteTitle = styled.div`
  ${tw`sm:leading-none pb-5 md:pb-8 lg:pb-10 mr-6`}
  max-width: 340px;
  font-size: 30px;
  text-transform: uppercase;
`

const SocialLinksContainer = styled.div``

const SocialLinksContainerNoNav = styled.div`
  > div {
    justify-content: center;
  }
`

export const DefaultHeader = ({
  title,
  navigation,
  extra,
  burgerMenu: forceBurgerMenu = false,
  logo = null,
  fieldHideLogoSitename = false,
  search = false,
  openSearchOnLoad = false,
  socialLinks = null,
  fieldDisableMainNavigation = null,
  onSearch = () => {
    /* noop */
  }
}) => {
  const {
    ref: refNav,
    isComponentVisible: isNavComponentVisible,
    setIsComponentVisible: setIsNavComponentVisible
  } = useComponentVisible(false)
  const {
    ref: refSearch,
    isComponentVisible: isSearchComponentVisible,
    setIsComponentVisible: setIsSearchComponentVisible
  } = useComponentVisible(openSearchOnLoad || false)
  const [selectedNavItem, setSelectedNavItem] = useState(-1)

  const [isRendered, setIsRendered] = useState(false)
  useEffect(() => {
    setIsRendered(true)
  }, [])

  let interval

  const onMouseEnterHandler = (id) => {
    clearTimeout(interval)
    setSelectedNavItem(id)
    setIsSearchComponentVisible(false)
  }

  const onMouseLeaveHandler = () => {
    clearTimeout(interval)
    interval = setTimeout(() => {
      setSelectedNavItem(-1)
    }, 100)
  }

  const cancelMenuHide = () => {
    clearTimeout(interval)
  }

  useEffect(() => {
    if (isSearchComponentVisible) {
      setIsNavComponentVisible(false)
    } else if (isNavComponentVisible) {
      setIsSearchComponentVisible(false)
    }
  }, [isSearchComponentVisible, isNavComponentVisible])

  const getMenuContents = () => {
    if (forceBurgerMenu) {
      return (
        <>
          {(isNavComponentVisible || !isRendered) && (
            <div className="block bg-white w-full flex relative shadow-lg">
              <MenuContentsMobile
                navigation={navigation}
                selectedNavItem={selectedNavItem}
                extra={extra}
                onCloseNav={() => {
                  setIsNavComponentVisible(false)
                }}
              />
            </div>
          )}
        </>
      )
    }
    return (
      <>
        {(!selectedNavItem || selectedNavItem !== -1 || !isRendered) && (
          <div
            className="hidden lg:block bg-white w-full flex relative shadow-lg"
            onMouseEnter={() => cancelMenuHide()}
            onMouseLeave={() => onMouseLeaveHandler()}
          >
            <MenuContents navigation={navigation} selectedNavItem={selectedNavItem} />
          </div>
        )}
        {(isNavComponentVisible || !isRendered) && (
          <div className="block lg:hidden bg-white w-full flex relative shadow-lg">
            <MenuContentsMobile
              navigation={navigation}
              selectedNavItem={selectedNavItem}
              extra={extra}
              onCloseNav={() => {
                setIsNavComponentVisible(false)
              }}
            />
          </div>
        )}
      </>
    )
  }

  const getMenuButton = () => {
    const burgerWrapperClasses = `${!forceBurgerMenu ? 'block lg:hidden' : 'block'} `
    let menuButton = (
      <NavButton onClick={() => setIsNavComponentVisible(true)} onFocus={() => setIsNavComponentVisible(true)}>
        <Icons.Burger />{' '}
        <span className="hidden sm:inline-block ml-2">
          <FormattedMessage id="header.menu" />
        </span>
      </NavButton>
    )

    if (isNavComponentVisible || !isRendered) {
      menuButton = (
        <NavButton active onClick={() => setIsNavComponentVisible(false)}>
          <span className="opacity-0 hidden sm:inline-block lg:hidden">
            <FormattedMessage id="header.menu" />
          </span>{' '}
          <Icons.Close />
        </NavButton>
      )
    }

    return <div className={burgerWrapperClasses}>{menuButton}</div>
  }

  const getSearchButton = () => {
    let searchButton = (
      <NavButton onClick={() => setIsSearchComponentVisible(true)} onFocus={() => setIsSearchComponentVisible(true)}>
        <Icons.Search />
      </NavButton>
    )

    if (isSearchComponentVisible || !isRendered) {
      searchButton = (
        <NavButton active onClick={() => setIsSearchComponentVisible(false)}>
          <Icons.Close />
        </NavButton>
      )
    }

    return (
      <div id="header-search" className="block">
        {searchButton}
      </div>
    )
  }

  const handleSearch = (str) => {
    setIsSearchComponentVisible(false)
    onSearch(str)
  }

  const getLogo = (hasCenteredLogo) => {
    if (logo && logo?.url && logo?.width && logo?.height) {
      const ratio = logo?.width / logo?.height
      let sizes
      if (ratio > 0.9 && ratio < 1.1) {
        sizes = {
          width: (60 * logo?.width) / logo?.height,
          height: (60 * logo?.height) / logo?.width,
          widthSm: (50 * logo?.width) / logo?.height,
          heightSm: (50 * logo?.height) / logo?.width,
          hasCenteredLogo: hasCenteredLogo
        }
      } else if (ratio > 1.5) {
        sizes = {
          width: (40 * logo?.width) / logo?.height,
          height: 40,
          widthSm: (30 * logo?.width) / logo?.height,
          heightSm: 30,
          hasCenteredLogo: hasCenteredLogo
        }
      } else {
        sizes = {
          width: 187,
          height: (187 * logo?.height) / logo?.width,
          widthSm: 139,
          heightSm: (139 * logo?.height) / logo?.width,
          hasCenteredLogo: hasCenteredLogo
        }
      }

      return (
        <Logo {...sizes}>
          <img alt="logo" src={logo?.url} />
        </Logo>
      )
    }

    return null
  }

  const hasExtraMenu = Array.isArray(extra) && extra.length > 0
  const hasLogo = logo && logo?.url && logo?.width && logo?.height
  const hasCenteredLogo = logo && logo?.url && logo?.width && logo?.height && logo?.center
  const hasTallerLogo = hasLogo && logo?.width / logo?.height >= 1.1 && logo?.width / logo?.height <= 1.5
  const hasNavigation =
    (Array.isArray(navigation) && navigation.length > 0) || (Array.isArray(extra) && extra.length > 0)
  const hasSearch = search

  return (
    <>
      <header className="bg-c-header-bg w-full flex">
        <div className="container max-w-7xl flex flex-col content-center justify-between mx-auto px-6 md:px-10">
          {socialLinks && hasNavigation && hasTallerLogo ? (
            <div className="relative">
              <SocialLinksContainer className="pl-4 block lg:hidden items-center absolute top-4 right-0 md:top-6 md:right-10 z-20">
                {socialLinks}
              </SocialLinksContainer>
            </div>
          ) : null}

          {!fieldDisableMainNavigation && (
            <NavigationWrapper
              hasCenteredLogo={hasCenteredLogo}
              hasLogo={hasLogo}
              hasTallerLogo={hasTallerLogo}
              hasExtraMenu={hasExtraMenu}
              fieldHideLogoSitename={fieldHideLogoSitename}
            >
              {!fieldHideLogoSitename && logo && logo.url && logo.width && logo.height && (logo?.center || !hasNavigation) ? (
                <div>
                  <NavLink to="/">{getLogo(hasCenteredLogo)}</NavLink>
                </div>
              ) : null}

              {!forceBurgerMenu && Array.isArray(extra) ? (
                <div className="flex flex-row justify-end relative">
                  <div className="hidden lg:block relative mx-0 px-0 mb-4">
                    {Array.isArray(extra) &&
                      extra?.map((item, idx) => {
                        return (
                          <ExtraNavItem key={`extra-nav-${item?.id}-${idx}`} to={item?.url}>
                            {item?.label}
                          </ExtraNavItem>
                        )
                      })}
                  </div>
                </div>
              ) : null}

              <NavigationBlock
                id="main-navigation-container"
                hasCenteredLogo={hasLogo && logo?.center}
                hasLogo={hasLogo}
                hasTallerLogo={hasTallerLogo}
              >
                {!fieldHideLogoSitename && logo && logo.url && logo.width && logo.height && hasNavigation && (
                  <div className="block pb-5 md:pb-8 lg:pb-10">
                    <NavLink to="/">{getLogo()}</NavLink>
                  </div>
                )}
                <NavigationLeftContainer id="main-navigation-left-outer" hasBurgerMenu={forceBurgerMenu}>
                  {!fieldHideLogoSitename && !logo && (
                    <NavLink to="/" className="no-underline">
                      <SiteTitle className="siteTitle">{title}</SiteTitle>
                    </NavLink>
                  )}
                  {!forceBurgerMenu && hasNavigation && (
                    <nav id="main-navigation-left-inner" className="hidden lg:flex">
                      {Array.isArray(navigation) &&
                        navigation?.map((nav, idx) => {
                          const hasItems = Array.isArray(nav?.items) && nav?.items?.length > 0
                          return (
                            <div
                              key={`left-${nav?.id}`}
                              className="inline"
                              onClick={hasItems ? () => onMouseEnterHandler(nav?.id) : undefined}
                              onMouseLeave={hasItems ? () => onMouseLeaveHandler(nav?.id) : undefined}
                              onFocus={hasItems ? () => onMouseEnterHandler(nav?.id) : undefined}
                              onBlur={hasItems ? () => onMouseLeaveHandler(nav?.id) : undefined}
                            >
                              <NavItem
                                role="menuitem"
                                hasItems={hasItems}
                                key={nav?.id}
                                to={nav?.url}
                                active={selectedNavItem === nav?.id}
                                onClick={(e) => {
                                  if (hasItems) {
                                    e.preventDefault()
                                  }
                                }}
                              >
                                {Array.isArray(nav?.items) && nav?.items?.length > 0 ? (
                                  <>
                                    <span className="flex items-center">{nav?.label}</span>
                                    <SelectChevron />
                                  </>
                                ) : (
                                  nav?.label
                                )}
                              </NavItem>
                              {(selectedNavItem === nav?.id || !isRendered) && (
                                <MenuContainer className={isRendered ? 'absolute' : 'relative'}>
                                  {nav?.items?.length ? getMenuContents() : null}
                                </MenuContainer>
                              )}
                            </div>
                          )
                        })}
                    </nav>
                  )}
                </NavigationLeftContainer>

                {socialLinks && hasNavigation && hasTallerLogo ? (
                  <SocialLinksContainer className="hidden lg:flex items-center pb-10">{socialLinks}</SocialLinksContainer>
                ) : null}

                {socialLinks && hasNavigation && !hasTallerLogo ? (
                  <SocialLinksContainer className="hidden md:flex items-center pb-10">{socialLinks}</SocialLinksContainer>
                ) : null}

                {socialLinks && !hasNavigation ? (
                  <SocialLinksContainer
                    className="hidden md:flex items-center "
                    css={[hasTallerLogo ? tw`-mt-28` : tw`-mt-10`]}
                  >
                    {socialLinks}
                  </SocialLinksContainer>
                ) : null}

                <div className="flex flex-row">
                  <div>
                    {hasNavigation && getMenuButton()}
                    {isNavComponentVisible && (
                      <div className={`${!forceBurgerMenu ? 'lg:hidden' : ''}`} ref={refNav}>
                        <MenuContainer className={isRendered ? 'absolute' : 'relative'}>
                          {getMenuContents()}
                        </MenuContainer>
                      </div>
                    )}
                  </div>

                  {hasSearch && getSearchButton()}
                </div>
              </NavigationBlock>
            </NavigationWrapper>
          )}
        </div>
      </header>

      {socialLinks && hasNavigation && !hasTallerLogo ? (
        <SocialLinksContainer className="pl-4 mb-4 block md:hidden items-center">{socialLinks}</SocialLinksContainer>
      ) : null}

      {socialLinks && !hasNavigation ? (
        <SocialLinksContainerNoNav className="block mb-2 md:hidden" css={[hasCenteredLogo && tw`-mt-6`]}>
          {socialLinks}
        </SocialLinksContainerNoNav>
      ) : null}

      <div ref={refSearch}>
        {search && (isSearchComponentVisible || !isRendered) ? (
          <SearchContents onSearch={handleSearch} onCloseSearch={() => setIsSearchComponentVisible(false)} />
        ) : null}
      </div>
    </>
  )
}

export default DefaultHeader
