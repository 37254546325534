import React from 'react'
import htmr from 'htmr'
import { Link } from 'gatsby'
import { styled } from 'twin.macro'
//
import { tableTransform } from '~storybook/tables'

const SITE_URL = process.env.SITE_URL || ''

const StyledContainer = styled.div`
  .text-align-left {
    text-align: left;
  }
  .text-align-center {
    text-align: center;
  }
  .text-align-right {
    text-align: right;
  }
`

const renderLink = ({ href, children, style }) => {
  const props = {}

  const to = href?.replace(SITE_URL, '')

  if (to?.startsWith('/')) {
    return <Link to={to} css={style}>{children}</Link>
  }

  if (to?.startsWith('http')) {
    props.target = '_blank'
    props.rel = 'noreferrer nofollow'
  }

  return (
    <a href={to} {...props} css={style}>
      {children}
    </a>
  )
}

const defaultTransform = {
  a: (node, props, style) => renderLink({ ...node, ...props, ...style})
}

export const Html = ({ value = '', transform = {} }) => (
  <StyledContainer>
    {htmr(value, { transform: { ...defaultTransform, ...tableTransform, ...transform }, dangerouslySetChildren: ['style'] })}
  </StyledContainer>
)

export default Html
